import React, {useEffect, useState} from "react";
import {View, ViewText} from "../../View";
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";
import * as core from "../../helpers/core"
import {DeviceStatus} from "./DeviceStatus"

export function Device({topic = null, id = null}) {

    const [deviceId, setDeviceId] = useState(id)
    const [showDetail, setShowDetail] = useState(true)
    const [showProfile, setShowProfile] = useState(true)
    const [showStatus, setShowStatus] = useState(true)
    const [title, setTitle] = useState("device")
    const [snapshot, setSnapshot] = useState(null)
    let auth = useAuth()
    let userId = auth.userId

    let groupStyle = {display: "grid", gridTemplateColumns: "9em auto"}
    useEffect(() => {
        if (topic) {
            let payload = (topic.hasChild('init/payload')) ? topic.child('init/payload').val() : null
            let idP = payload?.deviceId || null
            if (idP) setDeviceId(idP)
        }
    }, [topic])

    useEffect(() => {
        if (!deviceId) return null
        let path1 = `device/device/${deviceId}`
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setTitle(snapshot.child('profile/code').val() || 'device')
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
        }
    }, [deviceId])

    if (!deviceId) return <View topic={topic}>`i need device id`</View>
    if (!(snapshot && snapshot.exists())) return <View topic={topic}>loading ...</View>

    if (!showDetail) return <View topic={topic}>
        <div className={"view-content"}>
            <div className={"view-side"}>
                <div className={"view-title action"} onClick={() => setShowDetail(!showDetail)}>
                    {title}
                </div>
            </div>
        </div>
    </View>

    let data = snapshot.val()

    return <View topic={topic}>
        <div className={"view-content"}>
            <div className={"view-side"}>
                <div className={"view-title action"} onClick={() => setShowDetail(!showDetail)}>
                    {title}
                </div>
                <div className={'view-item-group'}>- - -</div>
                <div className={'view-item action' + ((showProfile) ? " active" : "")} onClick={() => {
                    setShowProfile(!showProfile)
                }}
                >[ profile ]
                </div>
                <div className={'view-item action' + ((showStatus) ? " active" : "")} onClick={() => {
                    setShowStatus(!showStatus)
                }}
                >[ status ]
                </div>
            </div>

            {showProfile &&
            <div className={"view-detail"}>
                <ViewText title={'id'} value={deviceId} className={'view-item'}/>
                <ViewText title={'code'} value={data?.profile?.code}
                          className={'view-item'}/>
                <ViewText title={'name'} value={data?.profile?.name} className={'view-item'}/>
                <div className={'view-item-group'}>hub</div>
                <ViewText title={'hub'} value={data?.hub?.hubId} className={'view-item'}/>
                <ViewText title={'hub device id'} value={data?.hub?.hubDeviceId}
                          className={'view-item'}/>

                <div className={"view-item-group"}>telemetry</div>
                {Object.keys(data?.telemetry).map(telemetryId => {
                    return <ViewText value={telemetryId} className={'view-item'}/>
                })}

                <div className={"view-item-group"}>site</div>
                {Object.keys(data?.site).map(siteId => {
                    return <ViewText path={`/site/site/${siteId}/profile/name`} className={"view-item"}/>
                })}


                <div className={'view-item action'} onClick={() => {
                    core.createChatTopic(userId, 'device:device-edit', {deviceId: deviceId})
                }
                }>[ edit ]
                </div>
            </div>
            }

            {showStatus &&
            <div className={'view-detail'}>
                <DeviceStatus id={deviceId} />
            </div>
            }
        </div>
    </View>
}