import React from "react";
import {Topics} from "../Chat";
import {UserProfile} from "../User";
import {Intents} from "../Intent";

export function Scene() {

    return <div className={'scene-1'}>
        <div className={'flex row'}>
            <Intents/>
            <UserProfile/>
        </div>
        <div className={'flex row'}>
            <Topics/>
        </div>
    </div>
}