import React from "react";
import {View} from "../View";
import * as core from "../helpers/core";
import {useAuth} from "../Provider/ProviderRoot";
import {ListSchema} from "../Schema";

export function ViewList({topic, title, schemaPath, dataPath, editTopicName, createTopicName}) {

    let auth = useAuth()
    let userId = auth.userId

    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    function onSelect(item) {
        //console.debug("selected", item)
        if (userId) {
            let itemId = item.key
            if (!itemId) return
            core.createChatTopic(userId, editTopicName, {id: itemId})
        }
    }

    return (
        <View topic={topic}>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass}>
                        {title}
                    </div>

                    <div className={'view-item-group'}>---</div>
                    <div className={'view-item action'}
                         onClick={() => {
                             core.createChatTopic(userId, createTopicName)
                         }
                         }>
                        [ create ]
                    </div>
                    <div className={detailClass}>
                        <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
                    </div>
                </div>
            </div>
        </View>
    )
}
