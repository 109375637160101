import React from 'react'
import {ListSchema} from "../../Schema";
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function LegalEntitiesTable() {

    let schemaPath = "/schema/legalEntity/legalEntity-list"
    let dataPath = "/LegalEntity/LegalEntity"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        //console.debug("selected", item)
        if (userId) {
            let itemId = item.key
            if (!itemId) return
            //console.log(itemId)
            core.createChatTopic(userId, "legalEntity:legalEntity-edit", {LegalEntityId:itemId})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

