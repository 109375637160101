import React, {useEffect, useState} from "react";
import {ListenFirebaseDb} from "../../Provider/FirebaseDb";

/**
 * Edit Boolean in path
 * @param title
 * @param path
 * @param {false,null, string} trueIs default 'true'
 * @param {false,null, string} falseIs default 'null'
 * @param {boolean} defaultValue default is false
 * @returns {JSX.Element}
 * @constructor
 */
export function InputSwitch({title, path, trueIs = true, falseIs = null}) {

    const [snapshot, loading, error] = ListenFirebaseDb(path)
    const [value, setValue] = useState(false)

    let vertical = 'top'
    let horizontal = 'center'

    useEffect(() => {
        if (!snapshot) return
        if (!snapshot.exists()) return
        (snapshot.val() === trueIs) ? setValue(true) : setValue(false)

    }, [snapshot, loading, error])

    function handleChange() {

        let newValue = (!value) ? trueIs : falseIs
        snapshot.ref.set(newValue)
            .then(() => {
                setValue(!value)
                return null
            })
            .catch(err => {
                console.error(`path ${path} err:${err}`)
            })

    }

    return (
        <div className={'form-field-switch'} >
            <div className={"input-switch"}>
                <input key={path + "switch"}
                       onChange={handleChange}
                       checked={value}
                       type={'checkbox'}
                />
                <label key={path + "switch-d"} onClick={handleChange}/>
            </div>
            <label key={path + "switch"}>
                {title}
            </label>
        </div>
    )
}