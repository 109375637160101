import React, {useEffect, useState} from 'react'
import {useAuth} from "../../Provider/ProviderRoot";
import {Topic} from "../Topic";
import {firebaseDb} from "../../Provider/Connection";

export function Topics() {

    let auth = useAuth()
    let userId = auth?.userId

    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        let path = `/chat/user/${userId}/topic`
       // console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
        dbRef
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setLoading(false)
            }, error => {
                //console.error(`cannot fetch ${path} :`, error)
                setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId])

    if (loading || error) return null
    if (!(snapshot && snapshot.exists())) return null

    let normalized = snapshot.val()
    let items = []
    Object.keys(normalized).sort(
        (a, b) => {
            if (a > b) return 1
            if (a < b) return -1
            return 0
        }
    ).map(topicId => {
        items.push(<Topic topicId={topicId}/>)
    })

    return items
}

