import React, {useEffect, useState} from 'react'
import {InputSwitch, InputText} from "../../Form";
import {firebaseDb} from "../../Provider/Connection";
import * as core from "../../helpers/core"

/**
 * Edit/Create Indent
 * @param topic
 * @param code
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export function IntentEdit({topic = null, code = null, id = null}) {

    const [snapshot, setSnapshot] = useState(null)
    const [authorisationSwitches, setAuthorisationSwitches] = useState([])
    const [intentId, setIntentId] = useState(id)
    const [intentCode, setIntentCode] = useState(code)

    useEffect(() => {
        if (!intentId) return null

        let path1 = `/intent/intent/${intentId}`
        //console.log(`START subscription ${path}`)
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })

        let path2 = `/system/authorisation`
        let dbRef2 = firebaseDb.ref(path2)
        dbRef2
            .on('value', snapshot => {
                let html = []
                snapshot.forEach(child => {
                    html.push(<InputSwitch title={child.key} path={`/intent/authorisation/${child.key}/${intentId}`}
                                           trueIs={intentCode}/>)
                })
                setAuthorisationSwitches(html)
            }, error => {
                console.error(`cannot fetch ${path2} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
            dbRef2.off();
            //console.log(`END subscription ${path}`)
        }
    }, [intentId])

    useEffect(async () => {
        if (intentCode && !intentId) {
            let intentSnap = await firebaseDb.ref(`/intent/intentCode/${intentCode}`).once('value')
            if (!(intentSnap && intentSnap.exists())) return null
            setIntentId(intentSnap.hasChild('intentId') ? intentSnap.child('intentId').val() : null)
            if (!intentId) return null
        }
    }, [intentCode])

    useEffect(() => {
        if (topic) {
            let payload = (topic.hasChild('init/payload')) ? topic.child('init/payload').val() : null
            let codeP = payload?.intentCode || null
            let idP = payload?.intentId || null
            if (codeP) setIntentCode(codeP)
            if (idP) setIntentId(idP)
        }
    }, [topic])

    let viewClass = "view"
    let titleClass = "view-title"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"
    if (!snapshot) return (
        <div className={viewClass}>
            <div className={'view-actions'}>
                {topic && <div className={"action"} onClick={() => topic.ref.remove()}>[x]</div>}
            </div>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass}>
                        edit intent
                    </div>
                </div>
                <div className={detailClass}>
                    missing 'intent'
                </div>
            </div>
        </div>
    )
    return (
        <div className={viewClass}>
            <div className={'view-actions'}>
                {topic && <div className={"action"} onClick={() => topic.ref.remove()}>[ x ]</div>}
            </div>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass}>
                        intent
                    </div>
                    <div className={'view-item-group'}>- - -</div>
                    <div className={'view-item action'} onClick={() => {
                        firebaseDb.ref().child(`/intent/intent/${intentId}`).remove()
                        topic.ref.remove()
                    }}
                    >[ delelte ]
                    </div>
                </div>
                <div className={detailClass}>
                    <div className={'view-item'}>id: {intentId}</div>
                    <div className={'view-item-group'}>intent</div>
                    <InputText title={'code'} helperText={'domain:intent-name'}
                               path={`/intent/intent/${intentId}/profile/code`}/>
                    <div className={'view-item-group'}>meta data</div>
                    <InputText title={'name'}
                               path={`/intent/intent/${snapshot.key}/profile/name`}/>
                    <InputText title={'description'}
                               path={`/intent/intent/${snapshot.key}/profile/description`}/>
                    <div className={'view-item-group'}>web element</div>
                    <InputText title={'component name'}
                               path={`/intent/intent/${snapshot.key}/web/component`}/>
                    <div className={'view-item-group'}>authorisation</div>
                    {authorisationSwitches}
                </div>
            </div>
        </div>
    )
}

