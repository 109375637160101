import React from 'react'
import {ListSchema} from "../../Schema";
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function ContractsTable() {

    let schemaPath = "/schema/contract/contract-list"
    let dataPath = "/contract/contract"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        console.debug("selected", item)
        if (userId) {
            let contractId = item.key
            if (!contractId) return
            core.createChatTopic(userId, "contract:contract-edit", {contractId:contractId})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

