import React, {useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {ListenFirebaseDb} from "../../Provider/FirebaseDb";
import moment from "moment";
import {useNow} from "../../helpers/core";

export function ElectricityStatusTable() {

    let K = 1e9 // Convert J into GJ
    let Units = "[ GJ ]"
    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotDevices, loadingDevices, errorDevices] = ListenFirebaseDb('/device/device')
    const [snapshotCurrent, loadingCurrent, errorCurrent] = ListenFirebaseDb('/device/stateDevice/electricity-current/state')
    const [snapshotQuantity, loadingQuantity, errorQuantity] = ListenFirebaseDb('/device/stateDevice/electricity-energy_active_import_total/state')
    const [showDetail, setShowDetail] = useState(false)

    let timer = useNow()
    let currentHtml = []
    let quantityHtml = []

    if (snapshotCurrent && snapshotCurrent.exists()) {

        // normalized data
        let data = {}


        // device code, name
        snapshotCurrent.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/profile/code/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/profile/name/`).val() || "-"
        })


        // data
        let currentTotal = 0
        Object.keys(data).sort((a, b) => {
            // sort by code
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            let value = (Math.round(Number(data[deviceId].vl) * 10) / 10)
            currentTotal += value
            currentHtml.push(<tr key={deviceId + 'status electricity current'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{value}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })

        // add total row
        currentHtml.push(<tr key={"total status electricity current"} style={{fontWeight:'bold'}}>
            <td>total</td>
            <td align={'right'}>{currentTotal}</td>
        </tr> )
    }

    if (snapshotQuantity && snapshotQuantity.exists()) {
        let data = {}
        snapshotQuantity.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/profile/code/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/profile/name/`).val() || "-"
        })
        Object.keys(data).sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            quantityHtml.push(<tr key={deviceId + 'status electricity quantity'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{(Math.round(Number(data[deviceId].vl) / 1e3 * 10) / 10).toLocaleString()}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })
    }

    return (
        <div style={{display: "flex", flexFlow: "column wrap"}}>
            <div className={'view-item-group'}>{moment(timer).format('hh:mm')}</div>
            <div style={{display: "flex", flexFlow: "row wrap"}}>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>current [A]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{currentHtml}</tbody>
                </table>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>quantity [kWh]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{quantityHtml}</tbody>
                </table>
            </div>
        </div>
    )
}