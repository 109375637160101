import React, {useEffect, useState} from "react";
import {firebaseDb} from "./Connection";

export const UpdateFirebaseDb = (path, data) => {

    let dbRef = firebaseDb.ref(path)
    return dbRef.update(data)
}

export const PushFirebaseDb = (path, data) => {

    let dbRef = firebaseDb.ref(path)
    return dbRef.update(data)
}

export const RemoveFirebaseDb = (path) => {

    let dbRef = firebaseDb.ref(path)
    return dbRef.set(null)
}

export const GetFirebaseDb = (path, orderByKey, orderByChild ) => {

    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        //console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
            if (orderByKey) {
                dbRef.orderByKey()
            }
            if (orderByChild) {
                dbRef.orderByChild(orderByChild)
            }

        dbRef
            .once('value', snapshot => {
                setSnapshot(snapshot)
                setLoading(false)
            }, error => {
                console.error(`cannot fetch ${path} :`, error)
                setError(true)
            })

        // Cleanup subscription on unmount
        return () => {
            //dbRef();
            //console.log(`END subscription ${path}`)
        }
    }, [])
    return [snapshot, loading, error];
}

export const ListenFirebaseDb = (path, orderByKey, orderByChild ) => {

    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        //console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
        if (orderByKey) {
            dbRef.orderByKey()
        }
        if (orderByChild) {
            dbRef.orderByChild(orderByChild)
        }

        dbRef
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setLoading(false)
            }, error => {
                console.error(`cannot fetch ${path} :`, error)
                setError(true)
            })

        // Cleanup subscription on unmount
        return () => {
            dbRef.off();
            //console.log(`END subscription ${path}`)
        }
    }, [])
    return [snapshot, loading, error];
}

export async function GetAsyncFirebaseDb(path) {
    return firebaseDb.ref(path).once('value')
}