import React, {useEffect, useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";
import moment from "moment";

export function DeviceStatus({id = null}) {

    const [deviceId, setDeviceId] = useState(id)
    const [snapshot, setSnapshot] = useState(null)
    let auth = useAuth()
    let userId = auth.userId

    useEffect(() => {
        if (!deviceId) return null
        let path1 = `device/deviceState/${deviceId}/state`
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
        }
    }, [deviceId])

    if (!deviceId) return <div className={'view-item'}>I need device id</div>
    if (!(snapshot && snapshot.exists())) return <div className={'view-item'}>loading data ...</div>

    let data = snapshot.val()
    let html = []
    Object.keys(data).map(topic => {
        html.push(
            <div style={{marginBottom: "1em"}} key={'status' + deviceId + topic}>
                <div align={'center'} style={{fontSize: "1.5em"}}>{Number(data[topic]?.vl).toLocaleString()}</div>
                <div align={'center'}>{moment(data[topic]?.ts).toNow()}</div>
                <div align={'center'}>{topic}</div>
            </div>
        )
    })
    return html
}