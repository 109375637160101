import React, {useEffect, useState} from "react";
import {firebaseDb} from "../../Provider/Connection";
import {useAuth} from "../../Provider/ProviderRoot";

const core = require("../../helpers/core")

export function IntentsList({isAdminList = false}) {

    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotUpdated, setSnapshotUpdated] = useState(null)
    const [data, setData] = useState(null)
    const [showDomainDetail, setShowDomainDetail] = useState([])

    let domainStyle = {}
    let intenStyle = {}

    useEffect(() => {
        let path = isAdminList ? `/intent/intent` : `/user/user/${userId}/intent`
        //console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
        dbRef
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId, isAdminList])

    useEffect(() => {
        if (!snapshot) return null
        let temp = {}

        if (isAdminList) {
            snapshot.forEach(intentSnap => {
                let intent = intentSnap.val()
                let intentId = intentSnap.key
                let intentCode = intentSnap.child("profile/code").val()
                if (!intentCode) return null
                let domainCode = intentCode.split(":")[0] || "-"
                if (!Object.keys(temp).includes(domainCode)) temp[domainCode] = {name: domainCode, intent: {}}
                temp[domainCode].intent[intentId] = {
                    name: intentCode,
                    domain: domainCode,
                    run: function runOnClick() {
                        core.createChatTopic(userId, intentCode)
                    },
                    edit: function runOnClick() {
                        core.createChatTopic(userId, "intent:intent-edit", {intentCode:intentCode})
                    }
                }
            })
        } else {
            snapshot.forEach(authorisationCode => {
                authorisationCode.forEach(intent => {
                    let domain = intent.val().split(":")[0] || "-"
                    let intentCode = intent.val()
                    if (!Object.keys(temp).includes(domain)) temp[domain] = {name: domain, intent: {}}
                    temp[domain].intent[intent.key] = {
                        name: intent.val(),
                        domain: intent.val().split(":")[0] || "-",
                        run: function runOnClick() {
                            core.createChatTopic(userId, intent.val())
                        },
                        edit: function runOnClick() {
                            console.debug('create ', intent.val())
                            core.createChatTopic(userId, "intent:intent-edit", {intentCode: intent.val()})
                        }
                    }
                })
            })
        }
        setData(temp)
    }, [snapshotUpdated])

    if (!data) return <div>loading ...</div>
    let html = []
    Object.keys(data).sort((a, b) => {
            if (data[a].name > data[b].name) return 1
            if (data[a].name < data[b].name) return -1
            return 0
        }
    ).map(domainKey => { // DOMAIN
        html.push(
            <div key={'domain' + domainKey}
                 className={'view-item-group action'}
                 onClick={() => {
                     if (showDomainDetail.includes(domainKey)) {
                         // HIDE/REMOVE
                         setShowDomainDetail(showDomainDetail.filter(item => item != domainKey))
                     } else {
                         // SHOW
                         setShowDomainDetail([domainKey, ...showDomainDetail])

                     }
                 }}>{data[domainKey].name}</div>
        )
        if (showDomainDetail.includes(domainKey)) {
            Object.keys(data[domainKey].intent).sort((a, b) => {
                if (data[domainKey].intent[a].name > data[domainKey].intent[b].name) return 1
                if (data[domainKey].intent[a].name < data[domainKey].intent[b].name) return -1
                return 0
            }).map(intentKey => {
                //console.debug("INTENT", intentKey)
                let intent = data[domainKey].intent[intentKey]
                html.push(<div key={'intentList' + intentKey}
                               className={'view-item action'}
                               onClick={() => {
                                   if (isAdminList) {
                                       if (intent?.edit) intent.edit()
                                   } else {
                                       if (intent?.run) intent.run()
                                   }
                               }}
                >{intent.name}</div>)
            })
        }
    })


    return html
}