import React from 'react'
import {ListSchema} from "../../Schema";
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function MachinesTable() {

    let schemaPath = "/schema/Machine/Machine-list"
    let dataPath = "/Machine/Machine"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        //console.debug("selected", item)
        if (userId) {
            let itemId = item.key
            if (!itemId) return
            //console.log(itemId)
            core.createChatTopic(userId, "machine:machine-edit", {MachineId:itemId})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

