import React, {useEffect, useState} from "react";
import {useAuth} from "../Provider/ProviderRoot";
import {firebaseDb} from "../Provider/Connection";
import {View} from "../View";
import {InputText} from "../Form";

export function UserProfile() {
    let auth = useAuth()
    let userId = auth.userId

    const [showDetail, setShowDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let path = `/user/user/${userId}/profile`
        //console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
        dbRef
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setLoading(false)
            }, error => {
                console.error(`cannot fetch ${path} :`, error)
                setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId])

    if (loading) return <View title={'user profile'} subtitle={"loading"}/>
    if (error) return <View title={'user profile'} subtitle={"error"} detail={error}/>
    if (!snapshot) return null
    let data = snapshot.val()
    return <View>
        <div className={'view-actions'}></div>
        <div className={'view-content'}>
            <div className={'view-side'}>
                <div className={'view-title action' + ((showDetail) ? " active" : "")}
                     onClick={() => setShowDetail(!showDetail)}>{data?.name}</div>
                {showDetail && <>
                    <div className={'view-item'}>- - -</div>
                    <div className={'view-item action' + (isEdit ? " active" : "")} onClick={() => setIsEdit(!isEdit)}>[
                        edit ]
                    </div>
                </>}
            </div>
            {(showDetail && !isEdit) && <div className={'view-detail'}>
                <div className={'view-item'}>{data?.userCode}</div>
                <div className={'view-item'}>{data?.name}</div>
                <div className={'view-item'}>{data?.email}</div>
                <div className={'view-item'}>{data?.phone}</div>
                <div className={'view-item'}>{data?.postAddress}</div>
            </div>}
            {(showDetail && isEdit) &&
            <InputText path={`/user/user/${userId}/profile/userCode`} helperText={'usercode'} title={'usercode'}
                       uniquePath={`/user/userCode`}/>}
        </div>

    </View>
}