import React, {useEffect, useState} from "react";
import {firebaseDb} from "../../Provider/Connection";
import {InputText, InputSwitch} from "../../Form";

export function FormSchema({schemaPath, dataPath}) {
    const [fullSchema, setFullSchema] = useState(null)

    useEffect(() => {

        if (!schemaPath) return null

        let path1 = schemaPath
        //console.log(`START subscription ${path}`)
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setFullSchema(parseSchema(dataPath, snapshot.val(), snapshot.key))
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
        }

    }, [schemaPath, dataPath])

    if (!fullSchema) return <div className={'view-item'}>loading ...</div>
    return <div>{fullSchema}</div>
}


function parseSchema(dataPath, schemaData, schemaKey) {
    let form = []
    form.push(<div className={'view-item-group'}>{schemaKey}</div>)
    if (Object.keys(schemaData).includes("properties")) {
        Object.keys(schemaData.properties)
            .sort((a, b) => {
                if (schemaData.properties[a]?.priority > schemaData.properties[b]?.priority) return -1
                if (schemaData.properties[a]?.priority < schemaData.properties[b]?.priority) return 1
                return 0
            })
            .map(propertyId => {
                let property = schemaData.properties[propertyId]
                switch (property.type) {
                    case "string": {
                        form.push(typeString(propertyId, dataPath, property))
                        break
                    }
                    case "boolean": {
                        form.push(typeBoolean(propertyId, dataPath, property))
                        break
                    }
                    case "object": {
                        let newDataPath = dataPath + "/" + propertyId
                        form.push(parseSchema(newDataPath, property, propertyId))
                        break
                    }
                    default:
                        break
                }
            })
    }
    return form
}

function typeString(id, root, property) {
    let title = property['title'] || id
    let path = `${root}/${id}`
    return <InputText key={root + id + "form"} title={title} path={path}/>
}

function typeBoolean(id, root, property) {
    let title = property['title'] || id
    let path = `${root}/${id}`
    let isFalse = property.payload?.[false] || false
    let filterFalse = false
    if (isFalse === "isNull") filterFalse = true

    return <InputSwitch key={root + id + "form"} title={title} path={path}/>
}