import React, {useEffect, useState} from 'react'
import {firebaseDb} from "../../Provider/Connection";
import {View} from "../../View";
import {FormSchema} from "../../Schema/FormSchema";


export function SiteEdit({topic = null, id = null}) {

    let schemaPath = "/Schema/Site/Site"

    const [itemId, setItemId] = useState(id)

    useEffect(() => {
        if (topic) {
            let payload = (topic.hasChild('init/payload')) ? topic.child('init/payload').val() : null
            let idP = payload?.id || null
            if (idP) setItemId(idP)
        }
    }, [topic])

    if (!itemId) return <View topic={topic}>cannot find site</View>

    return (
        <View topic={topic}>
            <div className={"view-content"}>
                <div className={"view-side"}>
                    <div className={"view-title"}>
                        site
                    </div>
                    <div className={'view-item-group'}>- - -</div>
                    <div className={'view-item action'} onClick={() => {
                        firebaseDb.ref().child(`/Site/Site/${itemId}`).remove()
                        topic.ref.remove()
                    }}
                    >[ delete ]
                    </div>
                </div>
                <div className={"view-detail"}>
                    <div className={'view-item'}>{itemId}</div>
                    <FormSchema schemaPath={schemaPath} dataPath={`/Site/Site/${itemId}`}/>
                </div>
            </div>
        </View>
    )
}

