import React, {useState} from 'react'
import {ElectricityCurrentHistoryMonthlyTable} from "./ElectricityCurrentHistoryMonthlyTable"
import {ElectricityCurrentHistoryDailyTable} from "./ElectricityCurrentHistoryDailyTable"
import {ElectricityCurrentHistory15mTable} from "./ElectricityCurrentHistory15mTable"

export function ElectricityCurrentHistory({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showMonthly, setShowMonthly] = useState(true);
    const [showDaily, setShowDaily] = useState(false);
    const [show15m, setShow15m] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    let viewClass = "view"
    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    return (
        <div className={viewClass}>
            <div className={'view-actions'}>
                {topic && <div className={"action"} onClick={() => topic.ref.remove()}>[x]</div>}
            </div>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        electricity current history
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((showMonthly) ? " active" : "")}
                             onClick={() => setShowMonthly(!showMonthly)}>
                            [ monthly ]
                        </div>
                        <div className={'view-item action' + ((showDaily) ? " active" : "")}
                             onClick={() => setShowDaily(!showDaily)}>
                            [ daily ]
                        </div>
                        <div className={'view-item action' + ((show15m) ? " active" : "")}
                             onClick={() => setShow15m(!show15m)}>
                            [ 15m ]
                        </div>
                    </>
                    }
                </div>
                <div className={detailClass}>
                    {(showDetail && showMonthly) && <ElectricityCurrentHistoryMonthlyTable/>}
                </div>
                <div className={detailClass}>
                    {(showDetail && showDaily) && <ElectricityCurrentHistoryDailyTable/>}
                </div>
                <div className={detailClass}>
                    {(showDetail && show15m) && <ElectricityCurrentHistory15mTable/>}
                </div>
            </div>
        </div>
    )
}

