import React, {useEffect, useState} from 'react'
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";
import {Element} from "../../Element";

export function Topic({topicId}) {

    let auth = useAuth()
    let userId = auth?.userId

    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        let path = `/chat/user/${userId}/topic/${topicId}`
        //console.log(`START subscription ${path}`)
        let dbRef = firebaseDb.ref(path)
        dbRef
            .on('value', snapshot => {
                setSnapshot(snapshot)
                setLoading(false)
            }, error => {
                //console.error(`cannot fetch ${path} :`, error)
                setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId, topicId])

    if (loading || error) return null
    if (!snapshot) return null
    return <Element topic={snapshot}/>
}

