import React, {useState} from 'react'
import {DevicesConsumptionTable} from "./DevicesConsumptionTable"

export function ElectricityConsumption({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showDevices, setShowDevices] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    let viewClass = "view"
    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    return (
        <div className={viewClass}>
            <div className={'view-actions'}>
                {topic && <div className={"action"} onClick={() => topic.ref.remove()}>[x]</div>}
            </div>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        electricity consumption
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((showDevices) ? " active" : "")}
                             onClick={() => setShowDevices(!showDevices)}>
                            [ devices p.m. ]
                        </div>
                    </>
                    }
                </div>
                <div className={detailClass}>
                    {(showDetail && showDevices) && <DevicesConsumptionTable/>}
                </div>
            </div>
        </div>
    )
}

