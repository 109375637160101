import React from 'react'
import {ViewList} from "../component/ViewList";
import {ViewCreate} from "../component/ViewCreate";
import {ViewEdit} from "../component/ViewEdit";
import {IntentCreate, IntentEdit, Intents} from "../Intent"
import {UserProfile} from "../User"
import {
    BuildingCreate,
    BuildingEdit,
    Buildings,
    FloorCreate,
    FloorEdit,
    Floors,
    RoomCreate,
    RoomEdit,
    Rooms,
    SiteCreate,
    SiteEdit,
    Sites
} from "../Site";
import {LandCreate, LandEdit, Lands} from "../Land";
import {SpaceCreate, SpaceEdit, Spaces} from "../Space";
import {Electricity, ElectricityConsumption, ElectricityCurrentHistory} from "../Electricity"
import {Heat, HeatConsumption} from "../Heat"
import {useAuth} from "../Provider/ProviderRoot";
import {LegalEntities, LegalEntity, LegalEntityCreate, LegalEntityEdit} from "../LegalEntity"
import {Contract, ContractCreate, ContractEdit, Contracts} from "../Contract"
import {Device, DeviceEdit, Devices} from "../Device"
import {Agreement, AgreementCreate, AgreementEdit, Agreements} from "../Agreement"
import {Machine, MachineCreate, MachineEdit, Machines} from "../Machine"

let components = {
    notdefined: NotDefined,
    Agreements: Agreements,
    Agreement: Agreement,
    AgreementCreate: AgreementCreate,
    AgreementEdit: AgreementEdit,
    Machines: Machines,
    Machine: Machine,
    MachineCreate: MachineCreate,
    MachineEdit: MachineEdit,
    IntentCreate: IntentCreate,
    Intents: Intents,
    IntentEdit: IntentEdit,
    Devices: Devices,
    Device: Device,
    DeviceEdit: DeviceEdit,
    Sites: Sites,
    SiteEdit: SiteEdit,
    SiteCreate: SiteCreate,
    ElectricZones: ViewList,
    HeatingZones: ViewList,
    GasZones: ViewList,
    HotWatterZones: ViewList,
    ColdWatterZones: ViewList,
    ElectricZoneCreate: ViewCreate,
    HeatingZoneCreate: ViewCreate,
    GasZoneCreate: ViewCreate,
    HotWatterZoneCreate: ViewCreate,
    ColdWatterZoneCreate: ViewCreate,
    ElectricZoneEdit: ViewEdit,
    HeatingZoneEdit: ViewEdit,
    GasZoneEdit: ViewEdit,
    HotWatterZoneEdit: ViewEdit,
    ColdWatterZoneEdit: ViewEdit,
    Lands: Lands,
    LandEdit: LandEdit,
    LandCreate: LandCreate,
    Buildings: Buildings,
    BuildingEdit: BuildingEdit,
    BuildingCreate: BuildingCreate,
    Spaces: Spaces,
    SpaceEdit: SpaceEdit,
    SpaceCreate: SpaceCreate,
    Floors: Floors,
    FloorEdit: FloorEdit,
    FloorCreate: FloorCreate,
    Rooms: Rooms,
    RoomEdit: RoomEdit,
    RoomCreate: RoomCreate,
    UserProfile: UserProfile,
    Electricity: Electricity,
    ElectricityConsumption: ElectricityConsumption,
    ElectricityCurrentHistory: ElectricityCurrentHistory,
    HeatConsumption: HeatConsumption,
    Heat: Heat,
    LegalEntities: LegalEntities,
    LegalEntity: LegalEntity,
    LegalEntityCreate: LegalEntityCreate,
    LegalEntityEdit: LegalEntityEdit,
    Contracts: Contracts,
    Contract: Contract,
    ContractCreate: ContractCreate,
    ContractEdit: ContractEdit,
}
let componentParameters = {
    ElectricZones: {
        title: "electric zones",
        schemaPath: "/Schema/Site/Lookup/ElectricZone/CodeName",
        dataPath: "/Site/Lookup/ElectricZone/CodeName",
        editTopicName: "site:electricZone-edit",
        createTopicName: "site:electricZone-create"
    },
    HeatingZones: {
        title: "heating zones",
        schemaPath: "/Schema/Site/Lookup/HeatingZone/CodeName",
        dataPath: "/Site/Lookup/HeatingZone/CodeName",
        editTopicName: "site:heatingZone-edit",
        createTopicName: "site:heatingZone-create"
    },
    GasZones: {
        title: "natural gas zones",
        schemaPath: "/Schema/Site/Lookup/GasZone/CodeName",
        dataPath: "/Site/Lookup/GasZone/CodeName",
        editTopicName: "site:gasZone-edit",
        createTopicName: "site:gasZone-create"
    },
    ColdWatterZones: {
        title: "cold watter zones",
        schemaPath: "/Schema/Site/Lookup/ColdWatterZone/CodeName",
        dataPath: "/Site/Lookup/ColdWatterZone/CodeName",
        editTopicName: "site:coldWatterZone-edit",
        createTopicName: "site:coldWatterZone-create"
    },
    HotWatterZones: {
        title: "hot watter zones",
        schemaPath: "/Schema/Site/Lookup/HotWatterZone/CodeName",
        dataPath: "/Site/Lookup/HotWatterZone/CodeName",
        editTopicName: "site:hotWatterZone-edit",
        createTopicName: "site:hotWatterZone-create"
    },
    ElectricZoneCreate: {
        title: "create electric zone",
        schemaPath: "/Schema/Site/ElectricZone",
        dataPath: "/Site/ElectricZone",
        editTopicName: "site:electricZone-edit",
        createTopicName: "site:electricZone-create"
    },
    HeatingZoneCreate: {
        title: "create heating zones",
        schemaPath: "/Schema/Site/HeatingZone",
        dataPath: "/Site/HeatingZone",
        editTopicName: "site:heatingZone-edit",
        createTopicName: "site:heatingZone-create"
    },
    GasZoneCreate: {
        title: "create natural gas zones",
        schemaPath: "/Schema/Site/GasZone",
        dataPath: "/Site/GasZone",
        editTopicName: "site:gasZone-edit",
        createTopicName: "site:gasZone-create"
    },
    ColdWatterZoneCreate: {
        title: "create cold watter zones",
        schemaPath: "/Schema/Site/ColdWatterZone",
        dataPath: "/Site/ColdWatterZone",
        editTopicName: "site:coldWatterZone-edit",
        createTopicName: "site:coldWatterZone-create"
    },
    HotWatterZoneCreate: {
        title: "create hot watter zones",
        schemaPath: "/Schema/Site/HotWatterZone",
        dataPath: "/Site/HotWatterZone",
        editTopicName: "site:hotWatterZone-edit",
        createTopicName: "site:hotWatterZone-create"
    },
    ElectricZoneEdit: {
        title: "edit electric zone",
        schemaPath: "/Schema/Site/ElectricZone",
        dataPath: "/Site/ElectricZone",
        editTopicName: "site:electricZone-edit",
        createTopicName: "site:electricZone-create"
    },
    HeatingZoneEdit: {
        title: "edit heating zones",
        schemaPath: "/Schema/Site/HeatingZone",
        dataPath: "/Site/HeatingZone",
        editTopicName: "site:heatingZone-edit",
        createTopicName: "site:heatingZone-create"
    },
    GasZoneEdit: {
        title: "edit natural gas zones",
        schemaPath: "/Schema/Site/GasZone",
        dataPath: "/Site/GasZone",
        editTopicName: "site:gasZone-edit",
        createTopicName: "site:gasZone-create"
    },
    ColdWatterZoneEdit: {
        title: "edit cold watter zones",
        schemaPath: "/Schema/Site/ColdWatterZone",
        dataPath: "/Site/ColdWatterZone",
        editTopicName: "site:coldWatterZone-edit",
        createTopicName: "site:coldWatterZone-create"
    },
    HotWatterZoneEdit: {
        title: "edit hot watter zones",
        schemaPath: "/Schema/Site/HotWatterZone",
        dataPath: "/Site/HotWatterZone",
        editTopicName: "site:hotWatterZone-edit",
        createTopicName: "site:hotWatterZone-create"
    }
}
let actions = {}

export function Element({topic}) {
    //console.log(topic.val(), topic.hasChild('init/intent/web/component'))
    let auth = useAuth()
    let userId = auth.userId

    if (!topic) return null

    let componentId = (topic.hasChild('init/intent/web/component')) ? topic.child('init/intent/web/component').val() : 'notdefined'

    // RUN ACTION
    const action = actions[componentId]
    if (action) {
        console.log("run action")
        action(userId, topic)
        topic.ref.remove()
        return null
    }

    // RETURN ELEMENT
    const Component = components[componentId] || NotDefined
    const parameters = componentParameters[componentId] || null
    return <Component topic={topic} {...parameters}/>
}

function NotDefined({topic}) {
    console.debug(topic.val())
    if (!topic) return null
    let data = topic.val()
    if (!data) return null
    return <div key={'chat-topic' + topic.key} className={'box-1'}>
        <div className={'box-1-header'}>
            <div className={'box-1-header-action'} onClick={() => topic.ref.remove()}>[ x ]</div>
        </div>
        <div className={'box-1-content'}>
            <div className={'box-1-left'}>
                <div className={'box-1-title'}>
                    {data.init.intent.intentName}
                </div>
            </div>
            <div className={'box-1-right'}>
                <div className={'box-cell'}>intent id: {data.init.intentId}</div>
                <div className={'box-cell'}>actor: {data.init.actor}</div>
                <div className={'box-cell'}>web component: {data.init.intent?.web?.component}</div>
                <div className={'box-cell'}>payload: {JSON.stringify(data.init?.payload, null, 2)}</div>
            </div>
        </div>
    </div>
}