import React from 'react';
import ReactDOM from 'react-dom';
import {ProviderRoot} from "./Provider/ProviderRoot";
import App from "./App";

//import './scss/application.scss'
//import 'fontsource-roboto'
import {ViewportProvider} from "./Provider/ViewportProvider";

ReactDOM.render(
    <React.StrictMode>
        <ProviderRoot>
            <ViewportProvider>
            <App/>
            </ViewportProvider>
        </ProviderRoot>
    </React.StrictMode>,
    document.getElementById('root'),
);