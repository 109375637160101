import React from "react";
import {ListenFirebaseDb} from "../../Provider/FirebaseDb";
import {useAuth} from "../../Provider/ProviderRoot";

const core = require("../../helpers/core")

export function Intent({intentId}) {

    let auth = useAuth()
    let userId = auth.userId

    const [snapshot, loading, error] = ListenFirebaseDb(`intent/intent/${intentId}`)

    if (loading || error) return null


    let data = snapshot.val()
    return <div key={intentId} className={'box-cell action'}
                onClick={() => core.createChatTopic(userId, data.intentName)}>{data?.intentName}</div>
}