import React, {Suspense} from 'react';

import {useAuth} from "./Provider/ProviderRoot";
import './scss/application.scss'
import './App.scss'
import {World} from "./World";
import {LoadingPage} from "./LoadingPage";

//const AppAuthenticated = React.lazy(() => import("./AppAuthenticated"))


export default function App() {

    document.title = "a.l.f.r.e.d."
    let auth = useAuth();

    if (auth.user) {
        return (
            <Suspense fallback={<div>loading ...</div>}>
                <World/>
            </Suspense>
        )
    }

    return <LoadingPage/>

}
