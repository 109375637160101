import React, {useEffect, useState} from "react";
import {GetAsyncFirebaseDb, ListenFirebaseDb} from "../../Provider/FirebaseDb";
import {useAuth} from "../../Provider/ProviderRoot";

export function InputText({title, helperText = null, path, uniquePath = null}) {
    const [snapshot, loading, error] = ListenFirebaseDb(path)
    const [status, setStatus] = useState({})
    const [value, setValue] = useState("")

    let auth = useAuth()
    let authorisation = auth.authorisation

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = async (e) => {
        let text = e.target.value
        //console.log(`update path ${snapshot.ref.path} with data ${text}`)
        if (uniquePath) {
            let checkPath = uniquePath + '/' + text
            //console.log(checkPath)
            let textExist = await GetAsyncFirebaseDb(checkPath)
            //console.log(textExist, textExist.exists(), textExist.val())
            if (textExist.exists()) {
                //console.error('This is not unique, I didn\'t saved data')
                //console.log(textExist.exists(), textExist.val())
                setStatus({type: 'error', message: 'This is not unique, I didn\'t saved data'})
                return
            }
        }

        if (text !== snapshot.val()) {
            snapshot.ref.set(text)
                .then(setStatus({type: "saved", message: `'${value}' saved ...`}))
                .catch(err => {
                    console.error(`path: ${path} err:${err}`)
                })
        }
    }


    useEffect(() => {
        if (!snapshot) return
        setValue(snapshot.val())
    }, [snapshot, loading, error])

    return (
        <div className={'form-field'}>
            <label>
                {title}
            </label>
            <input key={path}
                   id={path}
                   value={value}
                   placeholder={helperText}
                   onChange={handleChange}
                   onBlur={onBlur}
            />
        </div>

    )
}