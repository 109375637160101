import React, {useState} from "react";
import {AgreementsTable} from './AgreementsTable'
import {View} from "../../View";

export function Agreements({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showTable, setShowTable] = useState(true);

    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    return (
        <View topic={topic}>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        agreements
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((showTable) ? " active" : "")}
                             onClick={() => setShowTable(!showTable)}>
                            [ table ]
                        </div>
                    </>
                    }
                </div>
                <div className={detailClass}>
                    {(showDetail && showTable) && <AgreementsTable/>}
                </div>
            </div>
        </View>
    )
}

