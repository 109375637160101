export function parseSchema(schemaPath, dataPath, schemaData, fullSchema = {rows: {}, columns: [], sort: {}}) {

    if (!schemaData) return null

    if (Object.keys(schemaData).includes("properties")) {
        Object.keys(schemaData.properties).map(propertyId => {
            let property = schemaData.properties[propertyId]
            switch (property.type) {
                case "string": {
                    //form.push(typeString(propertyId, dataPath, property))
                    let {column, cell} = typeString(propertyId, dataPath, property)
                    if (!(fullSchema.columns.some(a => a.id === column.id))) fullSchema.columns.push(column)
                    fullSchema.rows[cell.id] = cell
                    break;
                }
                default:
                    break
            }
        })
    }
    fullSchema.sort = schemaData?.sort
    return fullSchema
}

function typeString(id, root, property) {
    let title = property['title'] || id
    let path = property?.path || "/"
    return {column: {id: id, title: title}, cell: {id: id, path: path}}
}