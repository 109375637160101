import React, {useEffect, useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";

export function ElectricityCurrentHistoryMonthlyTable() {

    let K = 1 // Convert A into A
    let Units = "[A]" // Convert A into A
    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotDevices, setSnapshotDevices] = useState(null)
    const [snapshotUpdated, setSnapshotUpdated] = useState(null)
    const [yearMonths, setYearMonths] = useState([]) // all yera-month
    const [filterYearMonth, setFilterYearMonth] = useState([]) // filtered year-month
    const [data, setData] = useState(null)
    const [dataSummary, setDataSummary] = useState(null)
    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        let path1 = `device/deviceStats/electricity-current/state_month`
        //console.log(`START subscription ${path}`)
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
                // All year-month's
                let ymT = []
                snapshot.forEach(childYearMonth => {
                    let yearMonth = childYearMonth.key
                    ymT.push(yearMonth)
                })

                // new period is First
                setYearMonths(ymT.sort().reverse())
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })

        let path2 = `device/device`
        //console.log(`START subscription ${path}`)
        let dbRef2 = firebaseDb.ref(path2)
        dbRef2
            .on('value', snapshot => {
                console.debug("DEVICES", snapshot.val())
                setSnapshotDevices(snapshot)
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path2} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
            dbRef2.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId])

    useEffect(() => {
        if (!snapshot) return null
        let dataT = {}
        let dataSummaryT = {}
        let headerT = []
        let rowT = []
        snapshot.forEach(childYearMonth => {
            let yearMonth = childYearMonth.key
            // showYearMonth === null -> header all
            if ((!filterYearMonth.length) || (filterYearMonth.length && filterYearMonth.includes(yearMonth))) {
                if (!headerT.includes(yearMonth)) headerT.push(yearMonth)

                // Data only for selected year-month
                childYearMonth.forEach(deviceSnap => {
                    let deviceId = deviceSnap.key
                    let deviceCode = (snapshotDevices) ? snapshotDevices.child(deviceId).child("profile/code").val() : "-"
                    if (!rowT.includes(deviceId)) rowT.push(deviceId)
                    if (!Object.keys(dataT).includes(deviceId)) dataT[deviceId] = {code: deviceCode}
                    let value = Math.round( Number(deviceSnap.child("vl").val())/ K)
                    console.log("value", value)
                    dataSummaryT[yearMonth] = dataSummaryT[yearMonth] ? dataSummaryT[yearMonth] + value : value
                    dataT[deviceId][yearMonth] = deviceSnap.val()
                    //console.log("deviceSnap", deviceSnap.val())
                })
            }
        })
        console.log("dataSummaryT", dataSummaryT)
        setHeaders(headerT)
        setRows(rowT)
        setData(dataT)
        setDataSummary(dataSummaryT)
    }, [snapshotUpdated, filterYearMonth])


    let headersHtml = []
    let rowsHtml = []
    if (rows && headers) {
        let header1Html = []
        let header2Html = []
        header1Html.push(<th>device</th>)
        header2Html.push(<th>{Units}</th>)
        // New period is First
        headers.sort().reverse().map(yearMonth => {
            header1Html.push(<th
                style={{paddingLeft: "1em", textAlign: "right"}}>{yearMonth}</th>)

        })
        headersHtml.push(<>
                <tr>{header1Html}</tr>
                <tr>{header2Html}</tr>
            </>
        )
        rows.sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            let rowHtml = []

            rowHtml.push(<td>{data[deviceId].code}</td>)

            // New Period is First
            headers.sort().reverse().map(yearMonth => {
                let maximum = Math.round(getValue(data, deviceId, yearMonth) / K)
                rowHtml.push(<td align={"right"}>{maximum}</td>)
            })
            rowsHtml.push(<tr>{rowHtml}</tr>)
        })

        let summaryHtml = []
        headers.sort().reverse().map(yearMonth => {
            console.log(dataSummary)
            let value = dataSummary[yearMonth] ? dataSummary[yearMonth] : "-"
            summaryHtml.push(<td align={"right"}>{value}</td>)
        })


        rowsHtml.push(
            <tr style={{fontWeight: "bold"}}>
                <td>Total</td>
                {summaryHtml}
            </tr>
        )
    }

    return (
        <div>
            <div className={'view-actions left'}>
                <div style={{display: "flex", flexFlow: "row wrap"}}>
                    {yearMonths.map(item => {
                        return (
                            <div key={item + "filter"}
                                 className={'action' + (filterYearMonth.includes(item) ? " active" : "")}
                                 onClick={() => {
                                     if (filterYearMonth.includes(item)) {
                                         setFilterYearMonth(filterYearMonth.filter(i => i != item))
                                     } else {
                                         setFilterYearMonth([item, ...filterYearMonth])
                                     }
                                 }}
                            >
                                [ {item} ]
                            </div>
                        )
                    })}

                </div>
            </div>
            <table>
                <thead>
                {headersHtml}
                </thead>
                <tbody>
                {rowsHtml}
                </tbody>
            </table>
        </div>
    )
}

/**
 * Get value
 * @param data
 * @param deviceId
 * @param yearMonth
 * @returns {string|number}
 */
function getValue(data, deviceId, yearMonth) {
    if (!Object.keys(data).includes(deviceId)) return ""
    if (!Object.keys(data[deviceId]).includes(yearMonth)) return ""
    if (!Object.keys(data[deviceId][yearMonth]).includes("vl")) return ""
    return Number(data[deviceId][yearMonth].vl)
}