import React from 'react'
import {ListSchema} from "../../Schema";
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function DevicesTable() {

    let schemaPath = "/schema/device/device-list"
    let dataPath = "/device/device"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        //console.debug("selected", item)
        if (userId) {
            let id = item.key
            if (!id) return
            core.createChatTopic(userId, "device:device", {deviceId:id})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

