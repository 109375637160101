import React, {useEffect, useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";

export function ElectricityCurrentHistoryDailyTable() {

    let K = 1 // Convert A into A
    let Units = "[A]" // Convert A into A
    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotDevices, setSnapshotDevices] = useState(null)
    const [snapshotUpdated, setSnapshotUpdated] = useState(null)
    const [yearMonths, setYearMonths] = useState([]) // all year-months
    const [selectedYearMonth, setSelectedYearMonth] = useState(null) // selected year-month
    const [fullDays, setFullDays] = useState([]) // all days for selected month
    const [data, setData] = useState(null)
    const [dataSummary, setDataSummary] = useState(null)
    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        let path1 = `device/deviceStats/electricity-current/state_day`
        //console.log(`START subscription ${path}`)
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
                // All year-month's
                let ymT = []
                snapshot.forEach(childYearMonth => {
                    let yearMonth = childYearMonth.key
                    ymT.push(yearMonth)
                })

                // new period is First
                setYearMonths(ymT.sort().reverse())
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })

        let path2 = `device/device`
        //console.log(`START subscription ${path}`)
        let dbRef2 = firebaseDb.ref(path2)
        dbRef2
            .on('value', snapshot => {
                console.debug("DEVICES", snapshot.val())
                setSnapshotDevices(snapshot)
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path2} :`, error)
                // setError(true)
            })
        // Clean subscription on unmount
        return () => {
            dbRef1.off();
            dbRef2.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId])

    useEffect(() => {
        if (!snapshot) return null
        if (!selectedYearMonth) return
        let dataT = {}
        let dataSummaryT = {}
        let headerT = []
        let rowT = []

        snapshot.child(selectedYearMonth).forEach(childFullDate => {
            let fullDate = childFullDate.key

            headerT.push(fullDate)

            // Data only for full date
            childFullDate.forEach(deviceSnap => {
                let deviceId = deviceSnap.key
                let deviceCode = (snapshotDevices) ? snapshotDevices.child(deviceId).child("profile/code").val() : "-"
                if (!rowT.includes(deviceId)) rowT.push(deviceId)
                if (!Object.keys(dataT).includes(deviceId)) dataT[deviceId] = {code: deviceCode}
                let value = Math.round(Number(deviceSnap.child("vl").val()) / K)
                //console.log("value", value)
                dataSummaryT[fullDate] = dataSummaryT[fullDate] ? dataSummaryT[fullDate] + value : value
                dataT[deviceId][fullDate] = deviceSnap.val()
                //console.log("deviceSnap", deviceSnap.val())
            })

        })
        //console.log("dataSummaryT", dataSummaryT)
        setHeaders(headerT)
        setRows(rowT)
        setData(dataT)
        setDataSummary(dataSummaryT)
    }, [snapshotUpdated, selectedYearMonth])


    let headersHtml = []
    let rowsHtml = []
    if (rows && headers) {
        let header1Html = []
        let header2Html = []
        header1Html.push(<th>device</th>)
        header2Html.push(<th>{Units}</th>)
        // New period is First
        headers.sort().reverse().map(fullDate => {
            header1Html.push(<th
                style={{paddingLeft: "1em", textAlign: "right", writingMode: "vertical-lr"}}>{fullDate}</th>)

        })
        headersHtml.push(<>
                <tr>{header1Html}</tr>
                <tr>{header2Html}</tr>
            </>
        )
        rows.sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            let rowHtml = []

            rowHtml.push(<td>{data[deviceId].code}</td>)

            // New Period is First
            let deviceMaximum = 0
            let maximums = []
            headers.sort().reverse().map(fullDate => {
                let maximum = Math.round(getValue(data, deviceId, fullDate) / K)
                if (maximum > deviceMaximum) {
                    deviceMaximum = maximum
                }
                maximums.push(maximum)
            })
            maximums.map(maximum => {

                rowHtml.push(<td align={"right"}
                                 style={{fontWeight: maximum == deviceMaximum ? "bold" : "normal"}} > {maximum}</td>)
                                 })
                rowsHtml.push(<tr>{rowHtml}</tr>)
            })

            let summaryHtml = []
            headers.sort().reverse().map(fullDate => {
                console.log(dataSummary)
                let value = dataSummary[fullDate] ? dataSummary[fullDate] : "-"
                summaryHtml.push(<td align={"right"}>{value}</td>)
            })


            rowsHtml.push(
                <tr style={{fontWeight: "bold"}}>
                    <td>Total</td>
                    {summaryHtml}
                </tr>
            )
        }

        return (
            <div>
                <div className={'view-actions left'}>
                    <div style={{display: "flex", flexFlow: "row wrap"}}>
                        {yearMonths.map(item => {
                            return (
                                <div key={item + "filter"}
                                     className={'action' + (selectedYearMonth === item ? " active" : "")}
                                     onClick={() => {
                                         if (selectedYearMonth === item) {
                                             setSelectedYearMonth(null)
                                         } else {
                                             setSelectedYearMonth(item)
                                         }
                                     }}
                                >
                                    [ {item} ]
                                </div>
                            )
                        })}

                    </div>
                </div>
                <table>
                    <thead>
                    {headersHtml}
                    </thead>
                    <tbody>
                    {rowsHtml}
                    </tbody>
                </table>
            </div>
        )
    }

    /**
     * Get value
     * @param data
     * @param deviceId
     * @param yearMonth
     * @returns {string|number}
     */
    function getValue(data, deviceId, yearMonth) {
        if (!Object.keys(data).includes(deviceId)) return ""
        if (!Object.keys(data[deviceId]).includes(yearMonth)) return ""
        if (!Object.keys(data[deviceId][yearMonth]).includes("vl")) return ""
        return Number(data[deviceId][yearMonth].vl)
    }