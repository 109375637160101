import React from "react";
import {useAuth} from "../Provider/ProviderRoot";

export function LoadingPage() {
    let auth = useAuth();

    return <div style={{display: "flex", flexFlow: "column nowrap", alignItems: "center"}}>
        <svg width={200} height={100} className={'landing-page-message'}>
            <g>
                <text x={"50%"} y={15} fontSize={"24px"} dominantBaseline={"hanging"}
                      textAnchor={"middle"}>A.L.F.R.E.D.
                    <animate id={"logoText"} attributeType={"XML"} attributeName={"opacity"} dur={"1s"} from={0} to={1}
                             repeatCount={"1"}/>
                </text>
                <text id={"google"} x={"50%"} y={50} fontSize={"18px"} dominantBaseline={"hanging"} textAnchor={"middle"} opacity={0} className={"action"} onClick={() => auth.signinByGoogle()}>[ login by
                    Google ]
                    <animate id={"googleFadeIn"} attributeType={"XML"} attributeName={"opacity"} dur={"1s"} from={0} to={1}
                             begin={"logoText.end"}
                             fill={"freeze"}
                             repeatCount={"1"}/>
                             <animate attributeName={"opacity"} dur={"1s"} values={"1;0.8;1"} begin={"googleFadeIn.end"} repeatCount={"indefinite"}/>
                </text>
            </g>

            <g>
                <line id={"topLine"} x1={0} y1={40} x2={"100%"} y2={40} strokeWidth={"2px"}>
                    <animate attributeName={"x1"} from={"50%"} to={0} dur={"1s"} repeatCount={"1"}/>
                    <animate attributeName={"x2"} from={"50%"} to={"100%"} dur={"1s"}
                             repeatCount={"1"}/>
                </line>
            </g>
        </svg>

    </div>
}