import {firebaseDb} from "../Provider/Connection";
import {GetAsyncFirebaseDb} from "../Provider/FirebaseDb";
import {v4} from "uuid"
import {useEffect, useState} from "react";

/**
 *
 * @returns {*}
 */
export function newUuid() {
    return v4()
}

/**
 * Return default width in percents or pixels
 * @param {0, int} columns
 * @returns {{pixels: number}|{percent: number}}
 */
export function columnRawWidth(columns = 1) {
    if (columns === 0) return {percent: '100%', pixels: null}
    let chars = 15
    let charSize = 16
    return {pixels: columns * chars * charSize, percent: null}
}

/**
 * Create topicId timestamp@intentId
 * @param userId
 * @param intentId
 * @returns {string}
 */
export function generateTopicId(userId, intentId) {
    return `${Date.now()}@${intentId}`
}

/**
 * Create Topic in Chat
 * @param userId
 * @param intentCode
 * @param payload
 * @returns {Promise<void>}
 */
export async function createChatTopic(userId, intentCode, payload = {}) {
//console.log(intentCode, payload)
    let intentIdSnap = await GetAsyncFirebaseDb(`/intent/intentCode/${intentCode}/intentId`)
    if (!intentIdSnap.exists()) return
    let intentId = intentIdSnap.val()
    let intentCopySnap = await GetAsyncFirebaseDb(`intent/intent/${intentId}`)
    let intentCopy = intentCopySnap.val()
    let topicId = generateTopicId(userId, intentId)
    let data = {
        actor: "user",
        userId: userId,
        intentId: intentId,
        topicId: topicId,
        intent: intentCopy,
        payload: payload
    }
    let dbRef = firebaseDb.ref(`/chat/user/${userId}/topic/${topicId}/init`)
    return dbRef.set(data)
}

/**
 *
 * @returns {Date}
 */
export function useNow() {
    const [date, setDate] = useState(new Date())
    useEffect(() => {
        var timerID = setInterval(() => tick(), 10000);
        return function cleanup() {
            clearInterval(timerID)
        }
    })

    function tick() {
        setDate(new Date());
    }

    return date
}

/**
 * true if one of strig in arraysOfString is in source
 * @param {object} source
 * @param {[]}arrayOsStrings
 */
export function includesOneOf(source, arrayOsStrings) {
    return Object.keys(arrayOsStrings).some(key => Object.keys(source).includes(arrayOsStrings[key]))
}