import React from "react";


export function View({topic, children}) {

    return (
        <div className={"view"}>
            <div className={'view-actions'}>
                {topic && <div className={"view-action action"} onClick={() => topic.ref.remove()}>[x]</div>}
            </div>
            {children}
        </div>
    )
}
