import React, {useEffect, useState} from "react";
import {firebaseDb} from "../Provider/Connection";

export function ViewText({title, path = null, value = null, type = 'row', className=""}) {

    const [_value, setValue] = useState(value)

    useEffect(() => {

        if (!path) return null

        let dbRef = firebaseDb.ref(path)
        dbRef
            .on('value', snapshot => {
                setValue(snapshot.val())
            }, error => {
                console.error(`cannot fetch ${path} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
           // console.debug('unsub')
            dbRef.off();
        }
    }, [path])

    return <div className={`viewtext ${type} ${className}`} key={path}>
        <div className={"viewtext-title"}>
            {title}
        </div>
        <div>
            {_value}
        </div>
    </div>
}