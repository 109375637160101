import React, {useState} from "react";
import {BuildingsTable} from './BuildingsTable'
import {View} from "../../../View";
import * as core from "../../../helpers/core";
import {useAuth} from "../../../Provider/ProviderRoot";

export function Buildings({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showTable, setShowTable] = useState(true);

    let auth = useAuth()
    let userId = auth.userId

    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    return (
        <View topic={topic}>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        buildings
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((showTable) ? " active" : "")}
                             onClick={() => setShowTable(!showTable)}>
                            [ table ]
                        </div>
                        <div className={'view-item action'}
                             onClick={() => {
                                 core.createChatTopic(userId, "site:building-create")
                             }
                             }>
                            [ create ]
                        </div>
                    </>
                    }
                </div>
                <div className={detailClass}>
                    {(showDetail && showTable) && <BuildingsTable/>}
                </div>
            </div>
        </View>
    )
}

