import React, {useState} from 'react'
import {IntentsList} from "./IntentsList"
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function Intents({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [isAdminList, setIsAdminList] = useState(false)

    let auth = useAuth()
    let authorisation = auth.authorisation
    let adminAuthorised = core.includesOneOf(authorisation, ["megaadmin", "admin:intent"])
    let viewClass = "view"
    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"


    return (
        <div className={viewClass}>
            <div className={'view-actions'}>
                {topic && <div className={"action"} onClick={() => topic.ref.remove()}>[x]</div>}
            </div>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        intents
                    </div>
                    {(showDetail && adminAuthorised) && (
                        <>
                            <div className={'view-item-group'}>- - -</div>
                            <div className={'view-item action ' + ((isAdminList) ? " active" : "")}
                                 onClick={() => setIsAdminList(!isAdminList)}>admin
                            </div>
                        </>
                    )}
                </div>
                {showDetail && <div className={detailClass}>
                    <IntentsList isAdminList={isAdminList}/>
                </div>}
            </div>
        </div>
    )
}

