import React, {useState} from "react";
import {View} from "../../View";
import {HeatStatusTable} from "./HeatStatus"

export function Heat({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showStatus, setShowStatus] = useState(true);
    const [detailViews, setDetailViews] = useState(['status'])

    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    function toggleShowDetailViews(code) {
        if (detailViews.includes(code)) {
            setDetailViews(detailViews.filter(a => a !== code))
        } else {
            setDetailViews([...detailViews, code])
        }
    }

    return (
        <View topic={topic}>
            <div className={contentClass}>
                <aside className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        heat
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((detailViews.includes('status')) ? " active" : "")}
                             onClick={() => toggleShowDetailViews('status')}>
                            [ status ]
                        </div>
                    </>
                    }
                </aside>
                <article className={'view-article'}>
                    {(showDetail) &&
                    detailViews.map(type => {
                        switch (type) {
                            case 'status':
                                return <HeatStatusTable/>
                            default:
                                break
                        }

                    })
                    }
                </article>
            </div>
        </View>
    )
}

