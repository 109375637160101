import React, {useEffect, useState} from "react";
import {firebaseDb} from "../../Provider/Connection";
import {ListenFirebaseDb} from "../../Provider/FirebaseDb";
import {schema} from "../index"

export function ListSchema({schemaPath, dataPath, onSelect}) {

    const [fullSchema, setFullSchema] = useState(null)
    const [snapshot, loading, error] = ListenFirebaseDb(dataPath)
    const [fullData, setFullData] = useState(null)

    // Set FullSchema
    useEffect(() => {

        if (!schemaPath) return null

        let path1 = schemaPath
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setFullSchema(schema.parseSchema(schemaPath, "", snapshot.val()))
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
        }

    }, [schemaPath, dataPath])

    /**
     * Fetch Data and set to FullData
     * generate HTML rows
     */
    useEffect(() => {
        if (!fullSchema) return;
        if (!(snapshot && snapshot.exists)) return
        let data = {}
        snapshot.forEach(item => {
            data[item.key] = {
                htmlRow: (<tr key={item.key + "table row"}
                              onClick={() => onSelect(item)}
                              className={(onSelect && "action")}
                >{dataToTableRow(item, fullSchema, onClickRow)}</tr>),
                snapshot: item
            }
        })
        //console.debug(data)
        setFullData(data)
        //console.debug("RECALC FULL DATA", Object.keys(data).length)
    }, [fullSchema, snapshot])

    function onClickRow(selectedRowItem) {
        if (onSelect) onSelect(selectedRowItem)
    }

    if (!(fullSchema)) return <div className={'view-item'}>loading schema ...</div>
    if (!(snapshot && snapshot.exists())) return <div className={'view-item'}>loading data ...</div>
    if (!fullData) return <div className={'view-item'}>loading data ...</div>

    // Generate Columns
    let columnsHtml = []
    fullSchema.columns.map(column => {
        columnsHtml.push(<td key={'column' + column?.id}>{column?.title || column.id}</td>)
    })
    let rowsHtml = []

    // SORT and Push html row
    Object.keys(fullData)
        .sort((a, b) => {
            let output = 0
            if (fullSchema.sort) {
                //
                // SORT
                //
                Object.keys(fullSchema.sort).map(sortCode => {
                    let sortPath = fullSchema.sort[sortCode]?.path
                    if (!sortPath) return
                    let direction = (fullSchema.sort[sortCode]?.direction !== "desc") ? "asc" : "desc"
                    if (fullData[a].snapshot.child(sortPath).val() > fullData[b].snapshot.child(sortPath).val()) output = (direction === "asc") ? 1 : -1
                    if (fullData[a].snapshot.child(sortPath).val() < fullData[b].snapshot.child(sortPath).val()) output = (direction === "asc") ? -1 : 1
                })
            }
            return output
        })
        .map(item => {
            rowsHtml.push(fullData[item].htmlRow)
        })

// Show HTML
    return <table className={'table-density'}>
        <thead>
        <tr key={'column thead'}>{columnsHtml}</tr>
        </thead>
        <tbody>{rowsHtml}</tbody>
    </table>
}

/**
 * generate HTML ROW
 * @param itemSnapshot
 * @param fullSchema
 * @return {*[]}
 */
function dataToTableRow(itemSnapshot, fullSchema) {
    let rowHtml = []

    fullSchema.columns.map(column => {
        let id = column.id
        let cellSchema = fullSchema.rows[id]
        //let path = cellSchema.path || `/${cellSchema.key}`
        let path = cellSchema.id
        console.log(cellSchema)
        //let value = itemSnapshot.hasChild(path) ? itemSnapshot.child(path).val() : ""
        let value = itemSnapshot.hasChild(path) ? itemSnapshot.child(path).val() : ""
        rowHtml.push(<td key={id + path}>{value}</td>)
    })

    return rowHtml
}
