import React, {useEffect, useState} from 'react'
import {firebaseDb} from "../Provider/Connection";

import {View} from "../View";
import {FormSchema} from "../Schema";

/**
 * Generic View to Edit
 * @param topic
 * @param id
 * @param title
 * @param schemaPath
 * @param dataPath
 * @return {JSX.Element}
 * @constructor
 */
export function ViewEdit({topic, id,  title, schemaPath, dataPath}) {

    const [itemId, setItemId] = useState(id)

    useEffect(() => {
        if (topic) {
            let payload = (topic.hasChild('init/payload')) ? topic.child('init/payload').val() : null
            let idP = payload?.id || null
            if (idP) setItemId(idP)
        }
    }, [topic])

    if (!itemId) return <View topic={topic}>cannot find {title}</View>

    return (
        <View topic={topic}>
            <div className={"view-content"}>
                <div className={"view-side"}>
                    <div className={"view-title"}>
                        {title}
                    </div>
                    <div className={'view-item-group'}>- - -</div>
                    <div className={'view-item action'} onClick={() => {
                        firebaseDb.ref().child(`${dataPath}/${itemId}`).remove()
                        topic.ref.remove()
                    }}
                    >[ delete ]
                    </div>
                </div>
                <div className={"view-detail"}>
                    <div className={'view-item'}>{itemId}</div>
                    <FormSchema schemaPath={schemaPath} dataPath={`${dataPath}/${itemId}`}/>
                </div>
            </div>
        </View>
    )
}

