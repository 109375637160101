import React from 'react'
import {ListSchema} from "../../Schema";
import {useAuth} from "../../Provider/ProviderRoot";
import * as core from "../../helpers/core"

export function ItemsTable() {

    let schemaPath = "/Schema/Site/Lookup/Space/CodeName"
    let dataPath = "/Site/Lookup/Space/CodeName"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        console.debug("selected", item)
        if (userId) {
            let itemId = item.key
            if (!itemId) return

            core.createChatTopic(userId, "site:space-edit", {id:itemId})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

