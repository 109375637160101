import React, {useState} from "react";
import {ElectricityStatusTable} from './ElectricityStatus'
import {View} from "../../View";

export function Electricity({topic, showDetailDefault = true}) {
    const [showDetail, setShowDetail] = useState(showDetailDefault);
    const [showStatus, setShowStatus] = useState(true);

    let titleClass = "view-title action"
    let sideClass = "view-side"
    let detailClass = "view-detail"
    let contentClass = "view-content"

    return (
        <View topic={topic}>
            <div className={contentClass}>
                <div className={sideClass}>
                    <div className={titleClass} onClick={() => setShowDetail(!showDetail)}>
                        electricity
                    </div>
                    {showDetail &&
                    <>
                        <div className={'view-item-group'}>---</div>
                        <div className={'view-item action' + ((showStatus) ? " active" : "")}
                             onClick={() => setShowStatus(!showStatus)}>
                            [ status ]
                        </div>
                    </>
                    }
                </div>
                <div className={detailClass}>
                    {(showDetail && showStatus) && <ElectricityStatusTable/>}
                </div>
            </div>
        </View>
    )
}

