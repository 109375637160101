import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyDY7vvKjZvb4RcBKBwCzERWSHSwhcxgorY",
    authDomain: "alfred-pda.firebaseapp.com",
    databaseURL: "https://alfred-pda-default-rtdb.firebaseio.com",
    projectId: "alfred-pda",
    storageBucket: "alfred-pda.appspot.com",
    messagingSenderId: "144309810077",
    appId: "1:144309810077:web:b61cb030d2f9dc74ec7574",
    measurementId: "G-KF7BWSVEJ8"
};

firebase.initializeApp(firebaseConfig);
export const providerGoogle = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestoreDb = firebase.firestore();
export const firebaseDb = firebase.database();
export default firebase;


