import React, {useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {ListenFirebaseDb} from "../../Provider/FirebaseDb";
import moment from "moment";
import {useNow} from "../../helpers/core";

export function HeatStatusTable() {

    let K = 1e9 // Convert J into GJ
    let Units = "[ GJ ]"
    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotDevices, loadingDevices, errorDevices] = ListenFirebaseDb('/device/device')
    const [snapshotPower, loadingPower, errorPower] = ListenFirebaseDb('/device/stateDevice/heat-power_W/state')
    const [snapshotQuantity, loadingQuantity, errorQuantity] = ListenFirebaseDb('/device/stateDevice/heat-quantity_J/state')
    const [snapshotFlowTemperature, loadingFlowTemperature, errorFlowTemperature] = ListenFirebaseDb('/device/stateDevice/heat-flow_temperature/state')
    const [snapshotReturnTemperature, loadingReturnTemperature, errorReturnTemperature] = ListenFirebaseDb('/device/stateDevice/heat-return_temperature/state')
    const [showDetail, setShowDetail] = useState(false)

    let timer = useNow()
    let powerHtml = []
    let quantityHtml = []
    let flowHtml = []
    let returnHtml = []

    if (snapshotPower && snapshotPower.exists()) {
        let data = {}
        snapshotPower.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/meta/deviceCode/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/meta/name/`).val() || "-"
        })
        Object.keys(data).sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            powerHtml.push(<tr key={deviceId + 'status power heat'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{(Math.round(Number(data[deviceId].vl) * 10) / 10 / 1E3)}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })
    }

    if (snapshotQuantity && snapshotQuantity.exists()) {
        let data = {}
        snapshotQuantity.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/meta/deviceCode/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/meta/name/`).val() || "-"
        })
        Object.keys(data).sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            quantityHtml.push(<tr key={deviceId + 'status quantity heat'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{(Math.round(Number(data[deviceId].vl) / 1E9 * 10) / 10)}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })
    }

    if (snapshotFlowTemperature && snapshotFlowTemperature.exists()) {
        let data = {}
        snapshotFlowTemperature.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/meta/deviceCode/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/meta/name/`).val() || "-"
        })
        Object.keys(data).sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            flowHtml.push(<tr key={deviceId + 'status flow temperature heat'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{(Math.round(Number(data[deviceId].vl) * 10) / 10)}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })
    }

    if (snapshotReturnTemperature && snapshotReturnTemperature.exists()) {
        let data = {}
        snapshotReturnTemperature.forEach(device => {
            data[device.key] = device.val()
            data[device.key]['code'] = snapshotDevices.child(`${device.key}/meta/deviceCode/`).val() || "-"
            data[device.key]['name'] = snapshotDevices.child(`${device.key}/meta/name/`).val() || "-"
        })
        Object.keys(data).sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            returnHtml.push(<tr key={deviceId + 'status return temperature heat'}>
                <td>{data[deviceId].code}</td>
                <td align={'right'}>{(Math.round(Number(data[deviceId].vl) * 10) / 10)}</td>
                <td>{moment(data[deviceId].ts).fromNow()}</td>
            </tr>)
        })
    }

    return (
        <section className={'view-detail'} style={{display: "flex", flexFlow: "column wrap"}}>
            <div className={'view-item-group'}>{moment(timer).format('hh:mm')}</div>
            <div style={{display: "flex", flexFlow: "row wrap"}}>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>power [kW]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{powerHtml}</tbody>
                </table>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>quantity [GJ]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{quantityHtml}</tbody>
                </table>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>flow [C]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{flowHtml}</tbody>
                </table>
                <table className={'table-density'}>
                    <thead>
                    <tr>
                        <td align={'right'}>device</td>
                        <td align={'right'}>return [C]</td>
                        <td align={'right'}></td>
                    </tr>
                    </thead>
                    <tbody>{returnHtml}</tbody>
                </table>
            </div>
        </section>
    )
}