import React from 'react'
import {ListSchema} from "../../../Schema";
import {useAuth} from "../../../Provider/ProviderRoot";
import * as core from "../../../helpers/core"

export function RoomsTable() {

    let schemaPath = "/Schema/Site/Lookup/Room/CodeName"
    let dataPath = "/Site/Lookup/Room/CodeName"
    let auth = useAuth()
    let userId = auth.userId
    function onSelect(item) {
        console.debug("selected", item)
        if (userId) {
            let itemId = item.key
            if (!itemId) return
            //console.log(siteId)
            core.createChatTopic(userId, "site:room-edit", {id:itemId})
        }
    }
    return (
      <ListSchema schemaPath={schemaPath} dataPath={dataPath} onSelect={onSelect}/>
    )
}

