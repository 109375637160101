import React, {createContext, useContext, useEffect, useState} from "react";
//import firebase from "firebase";
import {firebaseDb, providerGoogle} from "./Connection";

import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProviderRoot({children}) {
    const auth = useProviderAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProviderAuth() {
    const [userAuth, setUserAuth] = useState(null);
    const [userProvider, setUserProvider] = useState(null);
    const [userProviderId, setUserProviderId] = useState(null);
    const [user, setUser] = useState(null);
    const [userAvatarSource, setUserAvatarSource] = useState(null);
    const [userId, setUserId] = useState(null);
    const [authorisation, setAuthorisation] = useState({});
    const [filters, setFilters] = useState({});

    const signinByEmail = (email, password) => {
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(response => {
                setUserAuth(response.user);
                return response.user;
            });
    };

    const signinByGoogle = () => {
        return firebase
            .auth()
            .signInWithPopup(providerGoogle)
            .then(response => {
                setUserAuth(response.user);
                return response.user;
            });
    };

    const signup = (email, password) => {
        return firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(response => {
                //setUserAuth(response.user);
                return response.user;
            });
    };

    const signout = () => {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                setUserAuth(null);
            });
    };

    const sendPasswordResetEmail = email => {
        return firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                return true;
            });
    };

    const confirmPasswordReset = (code, password) => {
        return firebase
            .auth()
            .confirmPasswordReset(code, password)
            .then(() => {
                return true;
            });
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUserAuth(user);
                setUserAvatarSource(user.photoURL);
                //console.log('user auth')
            } else {
                setUser(false);
                //console.log('user no auth')
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!userAuth) {
            setUserProvider(null)
            setUserProviderId(null)
            return
        }

        const unsubscribe = firebaseDb.ref(`user/userProvider/${userAuth.uid}`)
            .on('value', snapshot => {
                if (!snapshot) {
                    return
                }
                setUserProvider(snapshot);
                setUserProviderId(snapshot.key)
            }, (error) => {
                console.error(`cannot fetch user provider #${userAuth.uid}: `, error)
            });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [userAuth]);

    useEffect(() => {
            if (!userProvider) {
                setUser(null)
                setUserId(null)
                setAuthorisation({})
                return
            }

            let data = userProvider.val();
            let {userId} = data;
            //console.log('userProvider', userAuth.uid, userProvider.val(), userId)
            //console.log(`user id #${userId}`, !userId)
            if (!userId) {
                setUser(null)
                setUserId(null)
                setAuthorisation({})
                return
            }
            //console.log('start fetch user')

            const unsubscribe = firebaseDb.ref(`user/user/${userId}`)
                .on('value', snapshot => {
                    if (snapshot) {

                        if (snapshot.hasChild('authorisation')) {
                            setAuthorisation(snapshot.child('authorisation').val())
                        }
                        setUser(snapshot);
                        setUserId(userId)
                    }
                }, (error) => {
                    console.error(`cannot fetch user #${userId}: `, error)
                })

            return () => unsubscribe();

        }, [userProvider]
    )

// Return the user object and auth methods
    return {
        user,
        userAuth,
        userProvider,
        userProviderId,
        authorisation,
        userId,
        userAvatarSource,
        filters,
        setFilters,
        signinByEmail,
        signinByGoogle,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset
    };
}
