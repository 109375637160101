import React, {useEffect, useState} from "react";
import {useAuth} from "../../Provider/ProviderRoot";
import {firebaseDb} from "../../Provider/Connection";

export function DevicesConsumptionTable() {

    let K = 1e9 // Convert J into GJ
    let Units = "[ GJ ]"
    let auth = useAuth()
    let userId = auth?.userId
    const [snapshot, setSnapshot] = useState(null)
    const [snapshotDevices, setSnapshotDevices] = useState(null)
    const [snapshotUpdated, setSnapshotUpdated] = useState(null)
    const [yearMonths, setYearMonths] = useState([]) // all yera-month
    const [filterYearMonth, setFilterYearMonth] = useState([]) // filtered year-month
    const [showDetail, setShowDetail] = useState(false)
    const [data, setData] = useState(null)
    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        let path1 = `device/deviceStats/heat-quantity_J/state_month`
        let dbRef1 = firebaseDb.ref(path1)
        dbRef1
            .on('value', snapshot => {
                setSnapshot(snapshot)
                // All year-month's
                let ymT = []
                snapshot.forEach(childYearMonth => {
                    let yearMonth = childYearMonth.key
                    ymT.push(yearMonth)
                })
                setYearMonths(ymT.sort().reverse())

                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path1} :`, error)
                // setError(true)
            })

        let path2 = `device/device`
        //console.log(`START subscription ${path}`)
        let dbRef2 = firebaseDb.ref(path2)
        dbRef2
            .on('value', snapshot => {
                console.debug("DEVICES", snapshot.val())
                setSnapshotDevices(snapshot)
                setSnapshotUpdated(Date.now())
            }, error => {
                console.error(`cannot fetch ${path2} :`, error)
                // setError(true)
            })
        // Cleanup subscription on unmount
        return () => {
            dbRef1.off();
            dbRef2.off();
            //console.log(`END subscription ${path}`)
        }
    }, [userId])

    useEffect(() => {
        if (!snapshot) return null
        let dataT = {}
        let headerT = []
        let rowT = []
        snapshot.forEach(childYearMonth => {
            let yearMonth = childYearMonth.key
            // filterYearMonth === empty -> header all
            if ((!filterYearMonth.length) || (filterYearMonth.length && filterYearMonth.includes(yearMonth))) {
                if (!headerT.includes(yearMonth)) headerT.push(yearMonth)

                // Data only for selected yera-month
                childYearMonth.forEach(deviceSnap => {
                    let deviceId = deviceSnap.key
                    let deviceCode = (snapshotDevices) ? snapshotDevices.child(deviceId).child("meta/deviceCode").val() : "-"
                    if (!rowT.includes(deviceId)) rowT.push(deviceId)
                    if (!Object.keys(dataT).includes(deviceId)) dataT[deviceId] = {code: deviceCode}
                    dataT[deviceId][yearMonth] = deviceSnap.val()
                })
            }
        })
        setHeaders(headerT)
        setRows(rowT)
        setData(dataT)
    }, [snapshotUpdated, filterYearMonth])


    let headersHtml = []
    let rowsHtml = []
    if (rows && headers) {
        let header1Html = []
        let header2Html = []
        header1Html.push(<th>device</th>)
        header2Html.push(<th>{Units}</th>)
        headers.sort().reverse().map(yearMonth => {
            {
                showDetail && header1Html.push(<th colSpan={3}>{yearMonth}</th>)
            }
            {
                !showDetail && header1Html.push(<th
                    style={{paddingLeft: "1em", textAlign: "right"}}>{yearMonth}</th>)
            }
            {
                showDetail && header2Html.push(<th>start</th>)
            }
            {
                showDetail && header2Html.push(<th>end</th>)
            }
            {
                showDetail ? header2Html.push(<th>cons</th>) : header2Html.push(<th></th>)
            }
        })
        headersHtml.push(<>
                <tr>{header1Html}</tr>
                <tr>{header2Html}</tr>
            </>
        )
        rows.sort((a, b) => {
            if (data[a].code > data[b].code) return 1
            if (data[a].code < data[b].code) return -1
            return 0
        }).map(deviceId => {
            let rowHtml = []

            rowHtml.push(<td>{data[deviceId].code}</td>)
            headers.sort().reverse().map(yearMonth => {
                let start = Math.round(getValue(data, deviceId, yearMonth, "s") / K * 10 ) /10
                let end = Math.round(getValue(data, deviceId, yearMonth, "e") / K * 10) / 10
                let consumption = Math.round(getValue(data, deviceId, yearMonth, "c") / K * 10) / 10
                {
                    showDetail && rowHtml.push(<td align={"right"}>{start}</td>)
                }
                {
                    showDetail && rowHtml.push(<td align={"right"}>{end}</td>)
                }
                rowHtml.push(<td align={"right"}>{consumption}</td>)
            })
            rowsHtml.push(<tr>{rowHtml}</tr>)
        })
    }

    return (
        <div>
            <div className={'view-actions left'}>
                <div className={'action' + (showDetail ? " active" : "")} onClick={() => setShowDetail(!showDetail)}>
                    [ detail ]
                </div>
                <div style={{display: "flex", flexFlow: "row wrap"}}>
                    {yearMonths.map(item => {
                        return (
                            <div key={item + "filter"}
                                 className={'action' + (filterYearMonth.includes(item) ? " active" : "")}
                                 onClick={() => {
                                     if (filterYearMonth.includes(item)) {
                                         setFilterYearMonth(filterYearMonth.filter(i => i != item))
                                     } else {
                                         setFilterYearMonth([item, ...filterYearMonth])
                                     }
                                 }}
                            >
                                [ {item} ]
                            </div>
                        )
                    })}

                </div>
            </div>
            <table>
                <thead>
                {headersHtml}
                </thead>
                <tbody>
                {rowsHtml}
                </tbody>
            </table>
        </div>
    )
}

/**
 * Get value
 * @param data
 * @param deviceId
 * @param yearMonth
 * @param type
 * @returns {string|number}
 */
function getValue(data, deviceId, yearMonth, type) {
    if (!Object.keys(data).includes(deviceId)) return ""
    if (!Object.keys(data[deviceId]).includes(yearMonth)) return ""
    if (!Object.keys(data[deviceId][yearMonth]).includes(type)) return ""
    if (!Object.keys(data[deviceId][yearMonth][type]).includes("vl")) return ""
    return Number(data[deviceId][yearMonth][type].vl)
}